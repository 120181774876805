<template>
  <va-card :title="title">
    <div>
      <form>
        <span class="va-form-label va-form-required-label">Tax Name</span>
        <va-input
          v-model="tax_name"
          type="text"
          pattern="^[A-Za-z0-9]+(?: +[A-Za-z0-9]+)*$"
          placeholder="Enter Tax Name"
          :error="!!taxNameErrors.length"
          :error-messages="taxNameErrors"
          :disabled="true"
        />
        <span class="va-form-label va-form-required-label">SAC Code</span>
        <va-input
          v-model="sac_code"
          type="text"
          maxlength=10
          pattern="^[A-Za-z0-9]+(?: +[A-Za-z0-9]+)*$"
          placeholder="Enter SAC Code"
          :error="!!sacCodeErrors.length"
          :error-messages="sacCodeErrors"
        />
        <span class="va-form-label va-form-required-label">State</span>
        <va-select
          v-if="isCreate"
          placeholder="Select State"
          searchable
          v-model="state"
          textBy="name"
          :options="stateList"
          :error="!!stateErrors.length"
          :error-messages="stateErrors"
          required="true"
          noClear
        />
        <va-input
          v-if="isUpdate"
          v-model="state"
          type="text"
          :disabled="true"
        />
        <span class="va-form-label va-form-required-label">Api for Get payment</span>
        <va-input
          v-model="api_get_payment_link"
          type="text"
          placeholder="Enter Api for Get payment"
          :error="!!apiPayStatusErrors.length"
          :error-messages="apiPayStatusErrors"
        />
        <span class="va-form-label va-form-required-label">Api for Payment Status</span>
        <va-input
          v-model="api_payment_link_status"
          type="text"
          placeholder="Enter Api for Payment Status"
          :error="!!apiPayStatusErrors.length"
          :error-messages="apiPayStatusErrors"
        />
        <span class="va-form-label va-form-required-label">Payment Fields</span>
        <div class="fields" v-for="(field,i) in paymentFields" :key="i">
          <va-input
            placeholder="Enter Key"
            v-model.trim="field.key"
            required="true"
          />
          <va-input
            placeholder="Enter Value"
            v-model.trim="field.value"
            required="true"
          />
          <va-button
            class="content_width toCenter"
            flat color="primary"
            @click.prevent="payment_fields_length++"
            icon="entypo entypo-plus" />
          <va-button
            v-if="(paymentFields.length != 1)"
            class="content_width toCenter"
            flat color="danger"
            @click.prevent="removePaymentField(i)"
            icon="entypo entypo-cancel" />
        </div>
        
              <u><b>SMS Details</b></u><br />
              <span class="va-form-label va-form-required-label">Request Type</span>
              <div class="row" style="padding-left: 10px;">
                <va-radio-button
                  option="http"
                  v-model="request_type"
                  label="HTTP"
                />
                <va-radio-button
                  option="https"
                  v-model="request_type"
                  label="HTTPS"
                />
              </div>
              <span class="va-form-label va-form-required-label">SMS Host</span>
              <va-input
                v-model.trim="sms_host"
                type="text"
                pattern="^[A-Za-z0-9-_.:]+(?: +[A-Za-z0-9-_.:]+)*$"
                placeholder="Enter SMS Host"
                :error="!!smsHostErrors.length"
                :error-messages="smsHostErrors"
              />
              <span class="va-form-label va-form-required-label">SMS Method</span>
              <va-select
                placeholder="Select SMS Method"
                v-model="sms_method"
                textBy="id"
                :options="smsMethodArr"
                :error="!!smsMethodErrors.length"
                :error-messages="smsMethodErrors"
              />
              <span class="va-form-label va-form-required-label">SMS Contact Key</span>
              <va-input
                v-model.trim="sms_property"
                type="text"
                pattern="^[A-Za-z0-9-_.:]+(?: +[A-Za-z0-9-_.:]+)*$"
                placeholder="Enter SMS Contact Key"
                :error="!!smscontactErrors.length"
                :error-messages="smscontactErrors"
              />
              <span class="va-form-label va-form-required-label">SMS API Context</span>
              <va-input
                v-model.trim="sms_api_context"
                type="text"
                pattern="^[A-Za-z0-9-_.:]+(?: +[A-Za-z0-9-_.:]+)*$"
                placeholder="Enter SMS API Context"
                :error="!!smsApiErrors.length"
                :error-messages="smsApiErrors"
              />
              <span class="va-form-label va-form-required-label">SMS Template Key</span>
              <va-input
                v-model.trim="sms_template_key"
                type="text"
                pattern="^[A-Za-z0-9-_.:]+(?: +[A-Za-z0-9-_.:]+)*$"
                placeholder="Enter SMS API Context"
                :error="!!smsTemplateKeyErrors.length"
                :error-messages="smsTemplateKeyErrors"
              />
              <label>Custom Fields</label>
              <div class="fields"  v-for="(field,i) in customFields" :key="i">
                <va-input
                  placeholder="Enter Key"
                  v-model.trim="field.key"
                  required="true"
                />
                <va-input
                  placeholder="Enter Value"
                  v-model.trim="field.value"
                  required="true"
                />
                <va-button
                  class="content_width toCenter"
                  flat color="primary"
                  @click.prevent="fields_length++"
                  icon="entypo entypo-plus" />
                <va-button
                  v-if="customFields.length != 1"
                  class="content_width toCenter"
                  flat color="danger"
                  @click.prevent="removeField(i)"
                  icon="entypo entypo-cancel" />
              </div>
        <div class="d-flex justify--end mt-3">
          <va-button type="submit" class="my-0 va-button-normal-cancel" @click.prevent="list()">Cancel</va-button>
          <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createBillSettings()">Create</va-button>
          <va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="updateBillSettings()">Update</va-button>
        </div>
      </form>
    </div>
  </va-card>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import config from '../../i18n/en.json'
Vue.use(vueResource)
export default {
  name: 'bill_setting',
  created () {
    this.getBillSettings();
    // this.$http.get(config.menu.host + '/bill_setting').then(response => {
    //   if (response.body.length > 0) {
    //     this.edit(response.body[0])
    //   } else {
    //     this.add()
    //   }
      this.$http.get(config.menu.host + '/org/stateCity').then(re => {
        this.state_city_mapper = re.body
        this.state_city_mapper.map(function (item, i) {
          item.id = i + 1
        })
        this.stateList = this.state_city_mapper
      })
    // })
  },
  data () {
    return {
      term: null,
      fields_length: 0,
      perPage: '10',
      perPageOptions: ['4', '6', '10', '20'],
      isshowgrid: false,
      isshowForm: false,
      isCreate: false,
      isUpdate: false,
      sac_code: '997331',
      payment_fields_length: 0, 
      smsMethodArr: [{ id: 'GET' }, { id: 'POST' }],
      tax_name: 'GST',
      title: '',
      state: '',
      taxNameErrors: [],
      sacCodeErrors: [],
      stateList: [],
      apiPayStatusErrors: [],
      api_get_payment_link: '',
      request_type:'',
      sms_host:'',
      sms_method:'',
      sms_property:'',
      sms_template:'',
      sms_template_key:'',
      sms_api_context:'',
      api_payment_link_status: '',
      apiPayStatusErrors: [],
      smsHostErrors: [],
      smsMethodErrors: [],
      smscontactErrors: [],
      smsApiErrors:[],
      smsTemplateKeyErrors:[],
      paymentFields: [{ key: '', value: '' }],
      customFields: [{ key: '', value: '' }],

    }
  },
  watch: {
  	fields_length () {
      this.customFields.push({ key: '', value: '' })
    },
    payment_fields_length () {
      this.paymentFields.push({ key: '', value: '' })
    },
  },
  computed: {
    formReady () {
      return !this.taxNameErrors.length && !this.sacCodeErrors.length && !this.stateErrors.length && !this.apiPayStatusErrors.length && !this.apiGetPayErrors.length && !this.smsHostErrors.length && !this.smsApiErrors.length && !this.smsMethodErrors.length && !this.smscontactErrors.length && !this.smsTemplateKeyErrors.length
    },
  },
  methods: {
    removePaymentField (index) {
      this.paymentFields = this.paymentFields.filter((_field, i) => i != index)
    },
    removeField (index) {
      this.customFields = this.customFields.filter((_field, i) => i != index)
    },
    getBillSettings () {
      this.$http.get(config.menu.host + '/bill_setting').then(response => {
        if (response.body.length > 0) {
          this.edit(response.body[0])
        } else {
          this.add()
        }
      })
    },
    createBillSettings () {
      this.taxNameErrors = this.tax_name ? [] : ['Tax Name is required']
      this.sacCodeErrors = this.sac_code ? [] : ['SAC Code is required']
      this.stateErrors = this.state ? [] : ['State is required']
      this.apiPayStatusErrors = this.api_payment_link_status ? [] : ['Api for Payment Status is required']
      this.apiGetPayErrors = this.api_get_payment_link ? [] : ['Api for Get Payment is required']
      this.smsHostErrors = this.sms_host ? [] : ['SMS Host is required']
      this.smsMethodErrors = this.sms_method ? [] : ['SMS Method is required']
      this.smsApiErrors = this.sms_api_context ? [] : ['SMS API is required']
      this.smscontactErrors = this.sms_property ? [] : ['SMS Contact Key is required']
      this.smsTemplateKeyErrors = this.sms_template_key ? [] : ['SMS Template Key is required']

      if (!this.formReady) {
        return
      }
      if (this.paymentFields[0].key == '') {
        return Vue.notify({ text: 'Please fill Payment Fields', type: 'error' })
      }
      var payload = {
        tax_name: this.tax_name,
        sac_code: this.sac_code,
        state: this.state.name,
        payment_fields: this.paymentFields,
        api_get_payment_link: this.api_get_payment_link,
        api_payment_link_status: this.api_payment_link_status,
        request_type: this.request_type,
        sms_host : this.sms_host,
        sms_property : this.sms_property,
        sms_template : this.sms_template,
        sms_template_key : this.sms_template_key,
        sms_api_context: this.sms_api_context,
        custom_fields: this.customFields,
        sms_method: this.sms_method.id ? this.sms_method.id : this.sms_method
      }
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + '/bill_setting', payload).then(responseData => {
        loader.hide()
        if (responseData && responseData.body) {
          Vue.notify({ text: responseData.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    updateBillSettings () {
      this.taxNameErrors = this.tax_name ? [] : ['Tax Name is required']
      this.sacCodeErrors = this.sac_code ? [] : ['SAC Code is required']
      this.stateErrors = this.state ? [] : ['State is required']
      this.apiPayStatusErrors = this.api_payment_link_status ? [] : ['Api for Payment Status is required']
      this.apiGetPayErrors = this.api_get_payment_link ? [] : ['Api for Get Payment is required']

      if (!this.formReady) {
        return
      }
      if (this.paymentFields[0].key == '') {
        return Vue.notify({ text: 'Please fill Payment Fields', type: 'error' })
      }
      var payload = {
        tax_name: this.tax_name,
        sac_code: this.sac_code,
        state: this.state,
        payment_fields: this.paymentFields,
        api_get_payment_link: this.api_get_payment_link,
        api_payment_link_status: this.api_payment_link_status,
        request_type: this.request_type,
        sms_host : this.sms_host,
        sms_method: this.sms_method.id ? this.sms_method.id : this.sms_method,
        sms_property : this.sms_property,
        sms_template : this.sms_template,
        sms_template_key : this.sms_template_key,
        custom_fields: this.customFields,
        sms_api_context: this.sms_api_context
      }
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host + '/bill_setting/' + this.bill_setting_id, payload).then(responseData => {
        loader.hide()
        if (responseData && responseData.body) {
          Vue.notify({ text: responseData.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    add () {
      this.title = 'Create Bill Setting'
      this.taxNameErrors = []
      this.sacCodeErrors = []
      this.stateErrors = []
      this.apiPayStatusErrors = []
      this.apiGetPayErrors = []
      this.smsHostErrors = []
      this.smsMethodErrors = []
      this.smscontactErrors = []
      this.smsApiErrors = []
      this.smsTemplateKeyErrors = []
      this.api_payment_link_status = ''
      this.api_get_payment_link = ''
      this.paymentFields = [{ key: '', value: ''}]
      this.customFields = [{ key: '', value: '' }]
      this.sac_code = '997331'
      this.tax_name = 'GST'
      this.state = ''
      this.sms_host = ''
      this.sms_method = ''
      this.sms_property = ''
      this.sms_template = ''
      this.sms_template_key = ''
      this.sms_api_context = ''
      this.isshowForm = true
      this.isshowgrid = false
      this.isUpdate = false
      this.isCreate = true
    },
    edit (row) {
      this.title = 'Update Bill Setting'
      this.sac_code = row.sac_code
      this.tax_name = row.tax_name
      this.bill_setting_id = row.bill_setting_id
      this.api_get_payment_link = row.api_get_payment_link
      this.api_payment_link_status = row.api_payment_link_status
      this.sms_method = row.sms_method
      this.sms_property = row.sms_property
      this.sms_host = row.sms_host
      this.sms_api_context = row.sms_api_context
      this.sms_template = row.sms_template
      this.request_type = row.request_type ? row.request_type : 'http'
      this.sms_template_key = row.sms_template_key
      this.paymentFields = (row.payment_fields) ? row.payment_fields : [{ key: '', value: ''}]
      var fields = row.custom_fields.filter(function (item) {
          return (item.key != row.sms_template_key)
        })  
      this.customFields = fields
      this.state = row.state
      this.isshowgrid = false
      this.isshowForm = true
      this.isCreate = false
      this.isUpdate = true
    },
    list () {
      this.isshowForm = false
      this.isshowgrid = true
      this.getBillSettings()
    },
  },
}
</script>


<style scoped>
.fields {
  display: grid;
  grid-template-columns: 6fr 6fr 6fr 6fr 1fr 1fr;
  grid-gap: 1rem;
  margin-top: 2rem;
}

.content_width {
  width: 30px;
  height: 30px;
}

</style>
